<template>
    <div class="w-full collapse" :class="{ active: input }">
        <div class="collapse-header" @click="toggleActive">
            <slot name="header"> header </slot>
            <i class="el-collapse-item__arrow el-icon-arrow-right cursor-pointer"></i>
        </div>
        <div class="collapse-content">
            <slot name="content"> content </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Collapse",
    props: {
        value: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        toggleActive() {
            this.input = !this.input;
        },
    },
};
</script>

<style lang="scss" scope>
.collapse {
    border: 1px solid #ccc;
    border-radius: 5px;
}
.collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.collapse-content {
    max-height: 0;
    overflow: hidden;
    // transition: max-height ease-out 0.3s;
}
.active {
    .collapse-content {
        max-height: 500px;
        // transition: max-height ease-in 0.3s;
    }
    .el-icon-arrow-right {
        transform: rotate(90deg);
    }
}
</style>
