<template>
    <div class="menu">
        <div class="sub-menu shadow-lg rounded-lg pb-5 bg-white relative">
            <router-link v-if="checkPermission('top_banners')" class="menu-item rounded-lg active" :to="{ name: 'top_banners' }">
                置頂 Banner
            </router-link>
            <router-link v-if="checkPermission('area_banners')" class="menu-item rounded-lg active" :to="{ name: 'area_banners' }">
                區塊 Banner
            </router-link>
            <router-link v-if="checkPermission('promote_img')" class="menu-item rounded-lg active" :to="{ name: 'promote_img' }">
                推廣大圖
            </router-link>
            <router-link v-if="checkPermission('newcomer_list')" class="menu-item rounded-lg active" :to="{ name: 'newcomer_list' }">
                猜你喜歡
            </router-link>
            <router-link v-if="checkPermission('footer_setting')" class="menu-item rounded-lg active" :to="{ name: 'footer_setting' }">
                Footer 設定
            </router-link>
            <div style="width: 100%; border-bottom: 1px solid #ccc; margin: 10px 0" class="line"></div>
            <router-link v-if="checkPermission('lastest_news')" class="menu-item rounded-lg active" :to="{ name: 'lastest_news' }">
                最新消息
            </router-link>
            <router-link v-if="checkPermission('fighting_rules')" class="menu-item rounded-lg active" :to="{ name: 'fighting_rules' }">
                教戰守則
            </router-link>
        </div>
        <button v-permission="['create']" class="btn-add orange-btn-800-lg w-full" @click="clickBtn">{{ btnText }}</button>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "SideBar",
    props: {
        btnText: {
            type: String,
            default: "+ 新增置頂 Banner",
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
    },
    methods: {
        clickBtn() {
            this.$emit("clickBtn");
        },
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    margin-right: 10px;
    .sub-menu {
        min-width: 248px;
        background: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 10px 15px;
        .menu-item {
            width: 100%;
            height: 40px;
            text-align: left;
            font-size: 14px;
            padding: 20px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px 0;
            &.router-link-active {
                background: #ff583314;
            }
        }
    }
    .btn-add {
        margin-top: 12px;
    }
}
</style>
