<template>
    <div class="flex mx-5 my-5">
        <SideBar :btnText="'+ 新增類別'" @clickBtn="addClass" />
        <div class="pb-5 flex-1">
            <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative">
                <LoadingComponent :isLoading="loading" />
                <div class="overflow-x-auto">
                    <div class="content">
                        <div class="draggable-table">
                            <Draggable tag="el-collapse" :list="nestList">
                                <Collapse v-for="(e, index) in nestList" :key="e.name" v-model="e.open" class="mb-3">
                                    <!-- 大類別 -->
                                    <template slot="header">
                                        <div class="input-title">
                                            <i class="fas fa-equals"></i>
                                            <el-input v-model="e.title" size="small" clearable @click.native.stop @keyup.native.stop> </el-input>
                                            <div @click.stop="addChild(index)">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                            <div @click.stop="removeClass(index)">
                                                <i class="fas fa-trash-alt"></i>
                                            </div>
                                        </div>
                                    </template>
                                    <template slot="content">
                                        <Draggable v-model="e.child" :group="'1'" class="child-out" @start="drag = true" @end="drag = false">
                                            <!-- 子類別 -->
                                            <div v-for="(element, index2) in e.child" :key="'idx-' + index + '-idx2' + index2" class="child-nav">
                                                <i class="fas fa-equals"></i>
                                                <el-input v-model="element.label" class="input-left" size="small" clearable> </el-input>
                                                <el-input v-model="element.link" class="input-right" size="small" clearable> </el-input>
                                                <div @click.stop="removeChild(index, index2)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </div>
                                            </div>
                                        </Draggable>
                                    </template>
                                </Collapse>
                            </Draggable>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button v-permission="['update']" class="orange-btn-800-lg text-md" @click="update">資料更新</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import SideBar from "../components/SideBar.vue";
import Draggable from "vuedraggable";
import Collapse from "@/components/Collapse.vue";
const defaultClass = {
    title: "",
    open: false,
    child: [],
};

const defaultChild = { label: "", link: "" };

export default {
    name: "FooterSetting",
    components: {
        LoadingComponent,
        SideBar,
        Draggable,
        Collapse,
    },
    data() {
        return {
            active: false,
            loading: false,
            nestList: [],
        };
    },
    methods: {
        // 取得 FOOTER 資料
        async getFooterSetting() {
            this.loading = true;
            const { status, data } = await this.$api
                .GetFooterSetting()
                .then((res) => {
                    return { status: res.status, data: [...res.data.footer] };
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                data.forEach((i) => {
                    this.nestList.push({ ...i, open: false });
                });
            }
        },
        // 確認傳入 Api 資料是否有沒設定到的值
        checkDataRight() {
            let result = true;
            this.nestList.forEach((i) => {
                if (!i.title) result = false;
                i.child.forEach((j) => {
                    if (!j.label || !j.link) {
                        result = false;
                    }
                });
            });
            return result;
        },
        // 更新 Api
        update() {
            const allow = this.checkDataRight();
            if (!allow) {
                alert("請確認資料是否填寫正確");
                return;
            }

            const nestList = this.nestList.map((i) => {
                return {
                    title: i.title,
                    child: i.child,
                };
            });

            this.loading = true;
            const request = {
                footer: nestList,
            };
            this.$api
                .UpdateFooterSetting(request)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "儲存成功",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: "儲存失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 新增大類別
        addClass() {
            const a = { ...defaultClass };
            const child = [...a.child];
            a.child = child;
            this.nestList.push(a);
        },
        // 移除大類別
        removeClass(idx) {
            const opts = {
                title: "刪除類別",
                message: "刪除類別會將內容連結一併刪除",
            };
            this.$pop.popConfirm(opts).then(
                () => {
                    this.nestList = this.nestList.filter((item, idex) => {
                        return idx !== idex;
                    });
                },
                () => {
                    // 取消
                    return;
                }
            );
        },
        // 新增子類別
        addChild(idx) {
            this.nestList[idx].child.push({ ...defaultChild });
            this.nestList[idx].open = true;
        },
        // 移除子類別
        removeChild(id, id2) {
            const opts = {
                title: "刪除連結",
                message: "是否將此連結刪除？",
            };
            this.$pop.popConfirm(opts).then(
                () => {
                    this.nestList[id].child = this.nestList[id].child.filter((item, idex) => {
                        return idex !== id2;
                    });
                },
                () => {
                    // 取消
                    return;
                }
            );
        },
    },
    mounted() {
        this.getFooterSetting();
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.el-collapse-item__content {
    padding-bottom: 0 !important;
}
.content {
    width: 100%;
    padding: 50px 10% 20px;
    .el-collapse-item {
        border: solid 1px #ccc;
        margin-bottom: 20px;
    }

    .el-collapse-item {
        border-radius: 5px;
    }

    .el-collapse {
        border: none;
    }

    ::v-deep .el-collapse-item__header {
        height: 53px;
    }

    ::v-deep .el-collapse-item__content {
        padding-bottom: 0;
    }

    .draggable-table {
        .input-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            height: 56px;
            i {
                color: #6a6a6a;
            }

            .fa-equals {
                margin: 0 20px;
                cursor: move;
                font-size: 20px;
            }
            .fa-plus {
                margin: 0 20px;
                font-size: 18px;
                cursor: pointer;
                &:hover {
                    color: #ccc;
                }
            }
            .fa-trash-alt {
                margin: 0 20px;
                font-size: 18px;
                cursor: pointer;
                &:hover {
                    color: #ccc;
                }
            }
        }
        .child-out {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .child-nav {
                width: 90%;
                height: 53px;
                margin: 10px 20px;
                border: 1px solid #ccc;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                .el-input {
                    margin-right: 10px;
                }
                .input-left {
                    flex: 1;
                }
                .input-right {
                    flex: 2;
                }
                .fa-equals {
                    margin: 0 20px;
                    cursor: move;
                    font-size: 18px;
                }
                .fa-trash-alt {
                    margin: 0 20px;
                    cursor: pointer;
                    font-size: 18px;
                    &:hover {
                        color: #ccc;
                    }
                }
            }
        }
    }
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
</style>
